import React from 'react';

import { Card, CardContent, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '../../store/store';
import { isHunger } from '../../util/constants';

import './Footer.css';

import styles from './Footer.module.scss';

export const Footer: React.FC = () => {
    const storeRestaurant = useSelector((state: RootState) => state.restaurant.restaurant);

    return (
        <div className={styles.footer}>
            <div className={styles.Footer}>
                <Card className={styles.card}>
                    <CardContent>
                        <Typography variant="h6">Kontakt</Typography>
                        <Typography>{storeRestaurant?.contact.street}</Typography>
                        <Typography>
                            {storeRestaurant?.contact.postalCode} {storeRestaurant?.contact.city}
                        </Typography>
                        <Typography>{storeRestaurant?.contact.phoneNumber}</Typography>
                    </CardContent>
                </Card>
                <Card className={styles.card}>
                    <CardContent>
                        <Typography variant="h6">Links</Typography>
                        <Link to="/impressum">
                            <Typography>Impressum</Typography>
                        </Link>
                        <Typography>
                            <Link to="/datenschutz">Datenschutz</Link>
                        </Typography>
                        <Typography>
                            <Link to="/agb">AGB</Link>
                        </Typography>
                        <Typography>
                            <Link to="/kontakt">Kontakt</Link>
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>Partner von:</Typography>
                {isHunger && (
                    <a href="https://hunger-hunger.de" target="_blank" rel="noreferrer">
                        hunger-hunger.de
                    </a>
                )}
                {!isHunger && (
                    <a href="https://bestelleck.de" target="_blank" rel="noreferrer">
                        bestelleck.de
                    </a>
                )}
            </div>
        </div>
    );
};
