import React from 'react';

import { Button, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '../../store/store';

import { DeliveryPlaces } from './DeliveryPlaces/DeliveryPlaces';
import { MenuFooter } from './Footer/Footer';
import { OpeningTimes } from './OpeningTimes/OpeningTimes';

import './Entry.css';

export const EntryScreen: React.FC = () => {
    const { restaurant } = useSelector((state: RootState) => state.restaurant);

    const isSmallScreen = useMediaQuery('(max-width:750px)');

    return (
        <div>
            <div className="imageContainer">
                <img alt="" src="doner.jpg" className="image"></img>
                <div className="centered">
                    <h1>{restaurant?.name}</h1>
                    <h3>Best food delivery in Mannheim</h3>
                </div>
                <div className="entryBox">
                    <div>We offer Pick-up and Food Delivery</div>
                    <Button color="primary" variant="contained" className="button" component={Link} to={'/menu'}>
                        See Menu
                    </Button>
                </div>
            </div>

            <h2>About {restaurant?.name}</h2>
            <hr className="divider"></hr>
            <div className="description">
                At {restaurant?.name} we offer meals of excellent quality and invite you to try our delicious food. The
                key to our success is simple: providing quality consistent food that taste great every single time. We
                pride ourselves on serving our customers delicious genuine dishes like: Salads, Burger, Pizza Eat
                delicious food. Grab a drink. But most of all, relax! We thank you from the bottom of our hearts for
                your continued support.
            </div>

            {restaurant && <OpeningTimes restaurant={restaurant} />}

            <DeliveryPlaces restaurant={restaurant} />
            {isSmallScreen && <MenuFooter />}
        </div>
    );
};
