import { createModel } from '@rematch/core';

import { deepEqual } from '../../util/utils';
import { CartItem } from '../types/cart.types';

import { RootModel } from './index';

export type CartState = {
    cart: CartItem[];
};

export const cart = createModel<RootModel>()({
    state: {
        cart: [],
    } as CartState,
    reducers: {
        addItem(state, item: CartItem) {
            let items = [...state.cart];

            const existing = items.findIndex((item) => {
                const { cartId: _, amount, ...item1 } = item;
                const { cartId: __, amount: amount2, ...item2 } = item;
                return deepEqual(item1, item2);
            });
            if (existing !== -1) {
                items[existing].amount += item.amount;
            } else {
                items = [...items, item];
            }

            return {
                cart: items,
            };
        },
        updateItem(state, updateItem: CartItem) {
            return {
                cart: state.cart.map((item) => {
                    if (item.cartId === updateItem.cartId) {
                        return updateItem;
                    }
                    return item;
                }),
            };
        },
        removeItem(state, removeItem: CartItem) {
            return {
                cart: state.cart.filter((item) => item.cartId !== removeItem.cartId),
            };
        },
        clearCart() {
            return {
                cart: [],
            };
        },
    },
});
