import React from 'react';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import styles from './Footer.module.scss';

export const MenuFooter: React.FC = () => {
    return (
        <div className={styles.footer}>
            <Button color="primary" variant="contained" className={styles.button} component={Link} to={'/menu'}>
                Order Now!
            </Button>
        </div>
    );
};
